import React from "react";
import { useStoreActions } from "easy-peasy";
import { Polygon } from "react-leaflet";
import flipCoords from "../../support/flipCoords";

export default ({ finding, highlighted }) => {
  const setFindingDetails = useStoreActions(
    (actions) => actions.AnalysisRuns.setFindingDetails
  );
  const setSelectedFinding = useStoreActions(
    (actions) => actions.AnalysisRuns.setSelectedFinding
  );
  const toggleFindingsPanel = useStoreActions(
    (actions) => actions.AnalysisRuns.toggleFindingsPanel
  );

  const transformed = () => {
    // Assume one feature per finding for now, maybe more later
    const coords = finding.location.features[0].geometry.coordinates[0];
    return flipCoords(coords);
  };

  const color =
    finding.reviewed === false
      ? "grey"
      : finding.false_positive === true
      ? "lightgreen"
      : "red";

  return (
    <>
      <Polygon
        positions={transformed()}
        color={color}
        fillOpacity={0.1}
        onClick={() => {
          setSelectedFinding(finding);
          setFindingDetails({
            finding_id: finding.finding_id,
          });
          toggleFindingsPanel(true);
        }}
      />
    </>
  );
};
