import styles from "./findingAccordion.module.css";
import React from "react";
import { useStoreActions } from "easy-peasy";
import { AccordionActions } from "@material-ui/core";
import {
  Tooltip,
  Button,
  Grid,
} from "@material-ui/core";

export default ({ finding }) => {
  const updateFindingDetails = useStoreActions(
    (actions) => actions.findings.updateFindingDetails
  );

  const resetFinding = () => {
    formData.boat_category = "Unknown";

    updateFindingDetails({
      ...formData,
      reviewed: false,
      false_positive: false,
      boat_category: "Unknown",
    });
  };

  const [formData] = React.useState({
    finding_id: finding.finding_id,
    analysis_run_id: finding.analysis_run_id,
    boat_category: finding.boat_category,
    location: finding.location,
    comment: finding.comment,
  });

  return (
    <>
      <AccordionActions>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          <Grid item xs={4}>
            <Tooltip title="Confirm that this is a refugee boat" arrow>
              <span>
                <Button
                  className={styles.findingActionButton}
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={
                    finding.reviewed === true &&
                    finding.false_positive === false
                  }
                  onClick={() => {
                    if (window.confirm("Are you sure this is a refugee boat?"))
                      updateFindingDetails({
                        ...formData,
                        reviewed: true,
                        false_positive: false,
                      });
                  }}
                >
                  Confirm
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip title="Confirm that this is NOT a refugee boat" arrow>
              <span>
                <Button
                  className={styles.findingActionButton}
                  aria-label="Reject"
                  color="primary"
                  size="small"
                  disabled={
                    finding.reviewed === true && finding.false_positive === true
                  }
                  variant="outlined"
                  onClick={() => {
                    if (
                      window.confirm("Are you sure this is NOT a refugee boat?")
                    )
                      updateFindingDetails({
                        ...formData,
                        reviewed: true,
                        false_positive: true,
                      });
                  }}
                >
                  Reject
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Tooltip
              title="Revert to an unreviewed state for this finding"
              arrow
            >
              <span>
                <Button
                  className={styles.findingActionButton}
                  aria-label="Reset"
                  size="small"
                  variant="outlined"
                  disabled={finding.reviewed === false}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to reset this finding's state?"
                      )
                    )
                      resetFinding();
                  }}
                >
                  Reset
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </AccordionActions>
    </>
  );
};
