import React from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useStoreActions, useStoreState } from "easy-peasy";
import Control from "react-leaflet-control";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./newFindingsEditor.css";
import ThumbUpAltTwoToneIcon from "@material-ui/icons/ThumbUpAltTwoTone";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import { Snackbar } from "@material-ui/core";
import { v4 } from "uuid";
import moment from "moment";

export default () => {
  const addFinding = useStoreActions(
    (actions) => actions.NewFindings.addNewFinding
  );
  const setSelectedFinding = useStoreActions(
    (actions) => actions.AnalysisRuns.setSelectedFinding
  );
  const toggleFindingsPanel = useStoreActions(
    (actions) => actions.AnalysisRuns.toggleFindingsPanel
  );
  const currentAnalysisRun = useStoreState(
    (state) => state.AnalysisRuns.currentAnalysisRun
  );
  const handleUpdateGeoFeature = useStoreActions(
    (actions) => actions.NewFindings.updateNewFindingGeometry
  );
  const newFindingGeometry = useStoreState(
    (state) => state.NewFindings.currentNewFindingGeometry
  );
  // Can only submit geometry if state represents valid polygon
  const canSubmit =
    newFindingGeometry != null &&
    newFindingGeometry.layer != null &&
    newFindingGeometry.layer != null &&
    newFindingGeometry.layer._latlngs != null;

  const handleLeaveCreateManualFinding = useStoreActions(
    (actions) => actions.NewFindings.leaveManualFindingMode
  );

  const triggerDrawMode = (e) => {
    document.querySelector(".leaflet-draw-draw-rectangle").click();
  };

  const handleCreateManualFinding = () => {
    const coords = [
      newFindingGeometry.layer._latlngs[0].map((coord) => [
        coord.lng,
        coord.lat,
      ]),
    ];

    const newFinding = {
      added_manually: true,
      finding_id: null,
      finding_temp_id: v4(), // Set temp UUID so we can identify finding later in list of all findings to set real ID coming from backend
      analysis_run_id: currentAnalysisRun.analysis_run_id,
      boat_category: "Unknown",
      false_positive: false,
      reviewed: false,
      confidence: 1.0,
      draft: true,
      sat_image_captured_at: moment().format("YYYY-MM-DD hh:mm:ss"),
      location: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: coords,
            },
            properties: {},
          },
        ],
      },
      comment: "This finding has been inserted manually by a reviewing person!",
    };
    addFinding(newFinding);
    setSelectedFinding(newFinding);
    toggleFindingsPanel(true);
  };

  return (
    <div>
      <Snackbar
        open={newFindingGeometry === null}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={"Draw a rectangle around the new boat finding and submit when you're ready!"}
      ></Snackbar>
      ;
      <FeatureGroup>
        <EditControl
          position="bottomright"
          onCreated={handleUpdateGeoFeature}
          onEdited={handleUpdateGeoFeature}
          onDeleted={handleUpdateGeoFeature}
          onMounted={triggerDrawMode}
          draw={{
            rectangle: true,
            polygon: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
          }}
        ></EditControl>
      </FeatureGroup>
      <Control position="bottomright">
        <Tooltip title="Submit findings area">
          <div className="submit-finding-button-outer leaflet-draw-toolbar leaflet-bar leaflet-draw-toolbar-top leaflet-draw-edit">
            <IconButton
              className="leaflet-button"
              onClick={handleCreateManualFinding}
              disabled={!canSubmit}
            >
              <ThumbUpAltTwoToneIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Control>
      <Control position="bottomright">
        <Tooltip title="Cancel manual finding creation">
          <div className="cancel-create-finding-button-outer leaflet-draw-toolbar leaflet-bar leaflet-draw-toolbar-top leaflet-draw-edit">
            <IconButton
              className="leaflet-button"
              onClick={handleLeaveCreateManualFinding}
            >
              <HighlightOffTwoToneIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Control>
    </div>
  );
};
