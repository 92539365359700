import styles from "./findingAccordion.module.css";
import React from "react";
import { useStoreActions } from "easy-peasy";
import DeviceHubTwoToneIcon from "@material-ui/icons/DeviceHubTwoTone";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import { AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { Typography } from "@material-ui/core";

export default ({ finding }) => {
  const setFindingDetails = useStoreActions(
    (actions) => actions.AnalysisRuns.setFindingDetails
  );
  const setSelectedFinding = useStoreActions(
    (actions) => actions.AnalysisRuns.setSelectedFinding
  );

  return (
    <AccordionSummary
      onClick={() => {
        setSelectedFinding(finding);
        setFindingDetails({ finding_id: finding.finding_id });
      }}
      expandIcon={<ExpandMoreIcon />}
      className={styles.title}
    >
      {/* TODO: TRANSFER STYLING TO CSS FILE, NOT INLINE */}
      <div className="finding-class-icon" style={{ paddingRight: "8px" }}>
        { finding.added_manually ? (
          <PanToolTwoToneIcon />
        ) : (
          <DeviceHubTwoToneIcon />
        )}
      </div>
      <Typography
        variant="subtitle1"
        style={finding.draft ? { fontStyle: "italic" } : {}}
      >
        {moment(finding.sat_image_captured_at).format("YYYY-MM-DD hh:mm:ss a")}
        {finding.draft ? " (Draft)" : ""}
      </Typography>
    </AccordionSummary>
  );
};
