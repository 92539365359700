import styles from "./findingsMap.module.css";
import React, { useEffect } from "react";
import { Map, ZoomControl, TileLayer, ScaleControl, withLeaflet } from "react-leaflet";
import NewFindingEditor from './newFindingsEditor'
import MeasureControlDefault from 'react-leaflet-measure';
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import featureCollection from "turf-featurecollection";
import "leaflet/dist/leaflet.css";
import 'leaflet-draw/dist/leaflet.draw.css';
import "react-leaflet-markercluster/dist/styles.min.css";
import Control from "react-leaflet-control";
import IconButton from "@material-ui/core/IconButton";
import ViewListIcon from "@material-ui/icons/ViewList";
import FindingMarker from "./findingMarker";
import flipCoords from "../../support/flipCoords";
import { useStoreActions, useStoreState } from "easy-peasy";
import AisLayer from "./ais/aisLayer";
import AisControl from './ais/aisControl';
import { useLocation, useHistory } from 'react-router-dom';
import TileFilterControl from './tileFilterControl';


export default ({ mapRef, findings, selectedFinding }) => {
  const manualFindingMode = useStoreState(
    (state) => state.NewFindings.manualFindingMode
  );

  const location = useLocation();
  const history = useHistory();

  const currentAnalysisRunId = useStoreState(
    (state) => state.AnalysisRuns.currentAnalysisRun.analysis_run_id
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const lat = parseFloat(query.get('lat')) || 35.991341;
    const lng = parseFloat(query.get('lng')) || 12.689209;
    const zoom = parseInt(query.get('zoom'), 10) || 7;

    mapRef.current.leafletElement.setView([lat, lng], zoom);

    const onMoveEnd = () => {
      const { lat, lng } = mapRef.current.leafletElement.getCenter();
      const zoom = mapRef.current.leafletElement.getZoom();
      const newUrl = `?lat=${lat.toFixed(6)}&lng=${lng.toFixed(6)}&zoom=${zoom}`;
      history.replace(newUrl);
    };

    mapRef.current.leafletElement.on('moveend', onMoveEnd);

    return () => {
      mapRef.current.leafletElement.off('moveend', onMoveEnd);
    };
  }, [location.search, history, mapRef]);


  useEffect(() => {
    fitBounds();
  }, [selectedFinding]);

  const toggleFindingsPanel = useStoreActions(
    (actions) => actions.AnalysisRuns.toggleFindingsPanel
  );

  const findingsCollection = (findings) => {
    let newCollection = featureCollection();
    newCollection.features = [];

    // Collect all features and return center of entire feature collection
    findings.forEach((finding) =>
      finding.location.features.forEach((feature) => {
        newCollection.features.push(feature);
      })
    );

    return newCollection;
  };

  const MeasureControl = withLeaflet(MeasureControlDefault);

  const measureOptions = {
    position: "topright",
    primaryLengthUnit: "meters",
    secondaryLengthUnit: "kilometers",
    primaryAreaUnit: "sqmeters",
    activeColor: "#db4a29",
    completedColor: "#9b2d14",
  };

  const calcBounds = (findings) => {
    return bbox(findingsCollection(findings));
  };

  const bounds = () => {
    // Center to selected finding center if present else center to all findings
    const defaultCoords = [-116, -8, 139, 72];
    return selectedFinding
      ? calcBounds([selectedFinding])
      : findings.length > 0
      ? calcBounds(findings)
      : defaultCoords;
  };

  const fitBounds = () => {
    const coords = bboxPolygon(bounds()).geometry.coordinates[0];
    mapRef.current.leafletElement.fitBounds(flipCoords(coords), {maxZoom : 18});
  };

  return (
    <Map zoomSnap={0.1} zoomControl={false} maxZoom={25} ref={mapRef}>
      <ZoomControl position="topright"></ZoomControl>
      <ScaleControl position="bottomright" />

      <Control position="topleft">
        <IconButton
          className={styles.openPanelButton}
          onClick={() => {
            toggleFindingsPanel(true);
          }}
        >
          <ViewListIcon />
        </IconButton>
      </Control>

      {/* Fallback layer in background */}
      <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" maxNativeZoom={19} maxZoom={25}/>
      {/* Our satellite images */}
      <TileLayer url={`${process.env.TILING_SERVER_BASE_URL}/tiles/${currentAnalysisRunId}/{z}/{x}/{y}.png`} maxNativeZoom={19} maxZoom={25}/>

      <MeasureControl {...measureOptions} />
      {manualFindingMode ? <NewFindingEditor /> : ""}

      {findings.map((finding) => (
        <FindingMarker
          key={`finding-marker-${finding.finding_id}`}
          id={`finding-marker-${finding.finding_id}`}
          finding={finding}
          highlighted={finding === selectedFinding}
          reviewed={finding.reviewed === true}
        />
      ))}

      <AisControl mapRef={mapRef} />
      <AisLayer />

      <TileFilterControl mapRef={mapRef} />
    </Map>
  );
};
