import styles from './findingsController.module.css';
import React from "react";
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import { useStoreActions, useStoreState } from "easy-peasy";

export default ({ mapRef }) => {
  const limitConfidence = useStoreActions(
    (actions) => actions.AnalysisRuns.limitConfidence
  );
  const currentConfidenceThreshold = useStoreState(
    (state) => state.AnalysisRuns.currentConfidenceThreshold
  );
  const currentAnalysisRun = useStoreState(
    (state) => state.AnalysisRuns.currentAnalysisRun
  );
  const cannotCreateNewFinding = useStoreState(
    (state) =>
      state.NewFindings.manualFindingMode === true ||
      state.NewFindings.currentNewFinding !== null
  );
  const startCreateNewFinding = useStoreActions(
    (actions) => actions.NewFindings.enterManualFindingMode
  )

  const handleConfidenceLimitation = (e) => {
    limitConfidence(e.target.value);
  };

  return (
    <>
      <div className={styles.confidenceFilterInput}>
        <TextField
          label="Min. confidence"
          type="number"
          value={currentConfidenceThreshold}
          inputProps={{ max: 1, step: "0.1" }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onChange={handleConfidenceLimitation}
        />
      </div>

      <div className={styles.confidenceFilterInput}>
        <TextField
          label="Num. findings"
          value={`${currentAnalysisRun.findings.length} / ${currentAnalysisRun.unfilteredFindings.length}`}
          disabled={true}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>

      <Link to={"#"} onClick={() => startCreateNewFinding()}>
        <Button
          className={styles.button}
          variant="contained"
          startIcon={<AddCircleOutlineTwoToneIcon />}
          disabled={cannotCreateNewFinding}
        >
          Add Finding
        </Button>
      </Link>

      <a
        href={`data:text/json;charset=utf-8, ${encodeURIComponent(
          JSON.stringify(currentAnalysisRun.findings)
        )}`}
        download={`${currentAnalysisRun.analysis_run_id}.json`}
      >
        <Button
          className={styles.button}
          variant="contained"
          startIcon={<GetAppTwoToneIcon />}
        >{`Download Json`}</Button>
      </a>

      <Link to={"/"}>
        <Button
          className={styles.button}
          variant="contained"
          startIcon={<CloseTwoToneIcon />}
        >
          Close
        </Button>
      </Link>
    </>
  );
};
