import styles from "./findingAccordion.module.css";
import React from "react";
import { useStoreActions } from "easy-peasy";
import { AccordionDetails } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";
import {
  Grid,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";

export default ({ finding }) => {
  const updateFindingDetails = useStoreActions(
    (actions) => actions.findings.updateFindingDetails
  );
  const postManualFinding = useStoreActions(
    (actions) => actions.NewFindings.postManualFinding
  );
  const deleteFinding = useStoreActions(
    (actions) => actions.NewFindings.removeNewFinding
  );

  const boat_categories = [
    { value: "Unknown", label: "Unknown" },
    { value: "RD", label: "Rubber dhingy" },
    { value: "TS", label: "Tank ship" },
    { value: "LY", label: "Luxury yacht" },
  ];

  const handleInputChange = (e) => {
    var values = formData;
    const { name, value } = e.target;
    setFormData({ ...values, [name]: value });
  };

  const [formData, setFormData] = React.useState({
    finding_id: finding.finding_id,
    sat_image_captured_at: finding.sat_image_captured_at,
    analysis_run_id: finding.analysis_run_id,
    boat_category: finding.boat_category,
    location: finding.location,
    comment: finding.comment,
    finding_temp_id: finding.finding_temp_id
  });

  return (
    <AccordionDetails>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {`Confidence: ${finding.confidence}`}
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            className="finding-comment-field"
            label="Leave a comment"
            name="comment"
            multiline
            rows={4}
            value={formData.comment}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            className="select-boat-type"
            select
            label="Select boat type"
            name="boat_category"
            value={formData.boat_category}
            onChange={handleInputChange}
            variant="outlined"
          >
            {boat_categories.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Button
            startIcon={<SendIcon />}
            variant="contained"
            type="submit"
            className={styles.findingActionButton}
            onClick={() => {
              if (
                window.confirm("Are you sure you want to commit these changes?")
              )
                if (finding.draft === true) {
                  postManualFinding({
                    ...formData,
                    reviewed: false,
                    false_positive: false,
                    added_manually: true,
                    confidence: 1,
                    detector_name: 'manual',
                    data_source_file: 'manual',
                  });
                } else {
                  updateFindingDetails({
                    ...formData,
                    reviewed: finding.reviewed,
                    false_positive: finding.false_positive,
                  });
                }
            }}
          >
            {finding.draft ? "Publish finding" : "Update details"}
          </Button>
        </Grid>

        {/* Cannot delete if already present in backend */}
        {!finding.draft ? (
          ""
        ) : (
          <Grid item xs={6}>
            <Button
              variant="contained"
              className={styles.findingActionButton}
              type="submit"
              startIcon={<DeleteIcon />}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this finding?"
                  )
                )
                  deleteFinding(finding.finding_id);
              }}
            >
              Delete Draft
            </Button>
          </Grid>
        )}
      </Grid>
    </AccordionDetails>
  );
};
