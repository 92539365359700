import styles from "./findingAccordion.module.css";
import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Accordion from "@material-ui/core/Accordion";
import FindingAccordionActions from "./findingAccordionActions";
import FindingAccordionDetails from "./findingAccordionDetails";
import FindingAccordionSummary from "./findingAccordionSummary";
import { Snackbar } from "@material-ui/core";

export default ({ finding, highlighted }) => {
  const setShowSuccessMessage = useStoreActions(
    (actions) => actions.findings.setShowSuccessMessage
  );
  const showSuccessMessage = useStoreState(
    (state) => state.findings.showSuccessMessage
  );

  const reviewStyle =
    finding.reviewed === false
      ? styles.grey
      : finding.false_positive === true
      ? styles.green
      : styles.red;

  return (
    <Accordion
      square={true}
      className={`${styles.finding} ${reviewStyle}`}
      expanded={highlighted}
    >
      <FindingAccordionSummary finding={finding} />

      {highlighted && !finding.draft ? (
        <FindingAccordionActions finding={finding} />
      ) : (
        ""
      )}
      {highlighted ? <FindingAccordionDetails finding={finding} /> : ""}

      <Snackbar
        open={showSuccessMessage.length > 0}
        onClose={() => setShowSuccessMessage("")}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={showSuccessMessage}
      ></Snackbar>
    </Accordion>
  );
};
