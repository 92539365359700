import styles from "./index.module.css";
import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import moment from "moment";

export default () => {
  const AnalysisRuns = useStoreState((state) => state.AnalysisRuns);
  const getAllAnalysisRuns = useStoreActions(
    (actions) => actions.AnalysisRuns.getAllAnalysisRuns
  );
  const setCurrentAnalysisRun = useStoreActions(
    (actions) => actions.AnalysisRuns.setCurrentAnalysisRun
  );
  const setSelectedFinding = useStoreActions(
    (actions) => actions.AnalysisRuns.setSelectedFinding
  );
  const deleteAnalysisRun = useStoreActions(
    (actions) => actions.AnalysisRuns.deleteAnalysisRun
  );

  useEffect(() => {
    setCurrentAnalysisRun(null);
    setSelectedFinding(null);
    getAllAnalysisRuns();
  }, []);

  const columns = [
    {
      flex: 1.5,
      field: "created_at",
      headerName: "Analyzed at",
      sortable: true,
      headerClassName: styles.tableHeader,
      renderCell: (params) => params.value,
    },
    {
      flex: 1,
      field: "newest_finding",
      headerName: "Latest Finding",
      sortable: true,
      headerClassName: styles.tableHeader,
      renderCell: (params) => moment(params.value).format("YYYY-MM-DD hh:mm:ss a"),
    },
    {
      flex: 1,
      field: "state",
      headerName: "Analysis State",
      headerClassName: styles.tableHeader,
    },
    {
      flex: 1,
      field: "foo",
      headerName: "Cases (Reviewed / Total)",
      headerClassName: styles.tableHeader,
      valueGetter: (params) =>
        `${params.getValue("num_findings_reviewed") || "0"} / ${params.getValue(
          "num_findings_total"
        ) || "0"}`,
    },
    {
      flex: 1.5,
      field: "id",
      headerName: "Actions",
      sortable: false,
      headerClassName: styles.tableHeader,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Link to={"/analysis_runs/" + params.value}>
            <Button variant="contained" color="primary">
              Show map
            </Button>
          </Link>

          <Button
            color="secondary"
            onClick={() => {
              if (window.confirm("Are you sure you want to delete this analysis run?")) {
                deleteAnalysisRun(params.value);
              }
            }}>Delete</Button>
        </>
      ),
    },
  ];

  // Map analysis_run_id to id
  if (AnalysisRuns !== undefined) {
    AnalysisRuns.allAnalysisRuns.map(
      (item) => (item.id = item.analysis_run_id)
    );
  }

  const rows = AnalysisRuns === undefined ? [] : AnalysisRuns.allAnalysisRuns;

  return (
    <div>
      <h1>All AnalysisRuns</h1>
      <div className={styles.datagridContainer}>
        <DataGrid
          autoPageSize
          rows={rows}
          columns={columns}
          sortModel={[{ field: "created_at", sort: "desc" }]}
          disableSelectionOnClick={true}
        />
      </div>
    </div>
  );
};
