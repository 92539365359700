import { action, thunk } from "easy-peasy";
import axios from "axios";
import { headers } from "./headers";

export const AnalysisRuns = {
  currentAnalysisRun: null,
  selectedFinding: null,
  allAnalysisRuns: [],
  showReviewed: true,
  currentSorting: "reviewState",
  currentConfidenceThreshold: "0.3",

  toggleShowReviewed: action((state, payload) => {
    state.showReviewed = !state.showReviewed;
  }),

  toggleFindingsPanel: action((state, payload) => {
    state.findingsPanelVisible = payload;
  }),

  setCurrentSorting: action((state, payload) => {
    state.currentSorting = payload;
  }),

  setCurrentAnalysisRun: action((state, payload) => {
    state.currentAnalysisRun = payload;
  }),

  setAllAnalysisRuns: action((state, payload) => {
    state.allAnalysisRuns = payload;
  }),

  addManualFinding: action((state, payload) => {
    state.currentAnalysisRun.findings.push(payload);
  }),

  removeManualFinding: action((state, payload) => {
    const findings = state.currentAnalysisRun.findings.filter(
      (p) => p.finding_id !== payload
    );
    state.currentAnalysisRun.findings = findings;
    state.selectedFinding = null;
  }),

  // Select passed finding if nothing else selected
  // Unselect passed finding if already selected
  // Select passed finding if prior conditions do not match
  setSelectedFinding: action((state, payload) => {
    if (state.selectedFinding === null) {
      state.selectedFinding = payload;
    }
    else if (state.selectedFinding.finding_id === payload.finding_id) {
      state.selectedFinding = null;
    }
    else {
      state.selectedFinding = payload;
    }
  }),

  setFindingDetails: action((state, payload) => {
    let findingIndex = state.currentAnalysisRun.findings.findIndex(
      (f) => f.finding_id === payload.finding_id
    );

    for (const [key, value] of Object.entries(payload)) {
      state.currentAnalysisRun.findings[findingIndex][key] = value;
    }
  }),

  setDetailsOfDraftFinding: action((state, payload) => {
    let findingIndex = state.currentAnalysisRun.findings.findIndex(
      (f) => f.finding_temp_id === payload.finding_temp_id
    );
    for (const [key, value] of Object.entries(payload)) {
      state.currentAnalysisRun.findings[findingIndex][key] = value;
    }
  }),

  limitConfidence: action((state, payload) => {
    state.currentConfidenceThreshold = payload;

    const filteredFindings = state.currentAnalysisRun.unfilteredFindings.filter(
      (f) => f.confidence > payload
    );
    state.currentAnalysisRun.findings = filteredFindings;
  }),

  sortFindings: action((state, payload) => {
    switch (state.currentSorting) {
      default:
      case "reviewState":
        // State can be either true, false or - if unreviewed - null
        var reviewed = state.currentAnalysisRun.findings.filter(
          (f) => f.reviewed === true
        );
        var unreviewed = state.currentAnalysisRun.findings.filter(
          (f) => !reviewed.includes(f)
        );

        const reviewedSorted = reviewed.sort((a, b) =>
          a.false_positive === true || a.reviewed === false ? 1 : -1
        );
        state.currentAnalysisRun.findings = [...reviewedSorted, ...unreviewed];
        break;
      case "confidence":
        const sortedFindings = state.currentAnalysisRun.findings.sort((a, b) =>
          a.confidence > b.confidence ? -1 : 1
        );
        state.currentAnalysisRun.findings = sortedFindings;
        break;
    }
  }),

  setCurrentAnalysisRunById: thunk(async (actions, payload, helpers) => {
    try {
      const AnalysisRun = helpers
        .getState()
        .allAnalysisRuns.find((p) => p.analysis_run_id === payload);

      if (AnalysisRun !== undefined) {
        // Fetch findings for analysis run
        const { data } = await axios.get(
          `${process.env.BACKEND_BASE_URL}/analysis_runs/${AnalysisRun.analysis_run_id}/findings/`,
          headers()
        );

        data.forEach((finding) => {
          // Set draft =  false for findings fetched from backend
          finding.draft = false;
        });

        // Filter by confidence threshold
        const currentConfidenceThreshold = helpers.getState()
          .currentConfidenceThreshold;
        const filtered_data = data.filter(
          (f) => f.confidence > currentConfidenceThreshold
        );

        actions.setCurrentAnalysisRun({
          ...AnalysisRun,
          findings: filtered_data,
          unfilteredFindings: data,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  }),

  getAllAnalysisRuns: thunk(async (actions, payload, helpers) => {
    try {
      const { data } = await axios.get(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/`,
        headers()
      );
      console.log(data);
      actions.setAllAnalysisRuns(data);
    } catch (error) {
      console.log(error.message);
    }
  }),

  createAnalysisRun: thunk(async (actions, payload) => {
    try {
      const { data } = await axios.post(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/`,
        payload,
        headers()
      );
      actions.setCurrentAnalysisRun(data);
    } catch (error) {
      console.log(error.message);
    }
  }),

  deleteAnalysisRun: thunk(async (actions, payload, helpers) => {
    try {
      await axios.delete(
        `${process.env.BACKEND_BASE_URL}/analysis_runs/${payload}`,
        headers()
      );

      const analysisRuns = helpers
        .getState()
        .allAnalysisRuns.filter((p) => p.analysis_run_id !== payload);
      actions.setAllAnalysisRuns(analysisRuns);
    } catch (error) {
      console.log(error.message);
    }
  }),
};
