import React, { useRef } from "react";
import { useStoreState } from "easy-peasy";
import FindingsMap from "./findingsMap";
import FindingsController from "./findingsController";
import FindingsPanel from "./findingsPanel";
import Grid from '@material-ui/core/Grid';

export default ({ currentAnalysisRun }) => {
  const selectedFinding = useStoreState((state) => state.AnalysisRuns.selectedFinding);
  const showReviewed = useStoreState((state) => state.AnalysisRuns.showReviewed);
  const mapRef = useRef();

  return (
    <>
      <FindingsMap
        mapRef={mapRef}
        findings={currentAnalysisRun.findings}
        selectedFinding={selectedFinding}
      />

      <FindingsPanel
        findings={currentAnalysisRun.findings}
        selectedFinding={selectedFinding}
        showReviewed={showReviewed}
      />

      <Grid container justify="flex-end">
        <FindingsController
          mapRef={mapRef}
          currentAnalysisRun={currentAnalysisRun}
        />
      </Grid>
    </>
  );
};
